import { isServerSide } from '@/helpers/isServerSide';

// TODO: There's circular dependency that prevent build on import
// import { PUBLIC_ENV_KEY } from 'next-runtime-env';
const PUBLIC_ENV_KEY = '__ENV';
const buildEnv = <T extends readonly string[]>(keys: T) =>
  Object.fromEntries(keys.map((key) => [key, process.env[key] ?? null])) as Record<T[number], string>;

const publicEnvKeys = [
  'APP_NAME',
  'APP_VERSION',
  'NODE_ENV',
  'ORIGIN',
  'LOGGING_LEVEL',

  'PAGE_404',

  'ASSET_PREFIX',
  'REST_BFF_URL',
  'GRAPHQL_URL',
  'DXP_CORE_BASE_URL',
  'PAYMENT_URL',
  'SEAWARE_BASE_URL',
  'CLIENT_ID',
  'CLIENT_SECRET',

  'AUTH_BASE_URL',
  'AUTH_IFRAME_URL',
  'CHATBOT_URL',

  'CMS_AUTH_BASE_URL',
  'CMS_BASE_URL',
  'CMS_COMPONENT_BASE_URL',
  'CMS_COMPONENT_BASE_URL_BOOK',

  'GTM_ID',
  'STORYLYTOKEN',
  'VWO_SDK_KEY',
  'RECAPTCHA_SITE_KEY',
  'RECAPTCHA_SCRIPT_URL',
] as const;

const staticEnv = {
  CONTEXT: '/book',
} as const;

export const publicEnv = Object.assign(buildEnv(publicEnvKeys), staticEnv);

const serverEnvKeys = [
  'CMS_REST_PASSWORD',
  'CMS_REST_USERNAME',
  'CONFIG_BASIC_TOKEN', // get rid after https://virginvoyages.atlassian.net/browse/MSH-108420
  'NEXT_CACHE_TIME',
  // Added for validation
  'REDIS_URL',
] as const;

const serverEnv = buildEnv(serverEnvKeys);

export const env = new Proxy(
  {
    ...publicEnv,
    ...serverEnv,
  },
  {
    get(target, key) {
      if (isServerSide() || target.NODE_ENV === 'test') {
        return target[key as keyof typeof target];
      }
      const map = window[PUBLIC_ENV_KEY];
      if (map) return map[key as string];
      console.error(
        `Unable to find window[${typeof PUBLIC_ENV_KEY === 'string' ? `"${PUBLIC_ENV_KEY}"` : PUBLIC_ENV_KEY}]`,
      );
    },
  },
);
